<template>
  <v-row class="ma-0 align-baseline">
    <v-col cols="12" sm="12">
      <div>
        <b>{{ meta.total }}</b> {{ $t("itemsPage.results") }}
      </div>
    </v-col>
    <v-col md="9" cols="12" sm="12" v-if="screenWidth > 500">
      <v-row class="align-baseline justify-start ">
        <v-col cols="6" sm="6" md="6" class="d-flex align-baseline px-0">
          <v-col cols="5" sm="5" >
            <div>{{ $t("itemsPage.perPage") }}</div>
          </v-col>
          <v-col cols="7" sm="7">
            <v-autocomplete
              flat
              filled
              :items="[3, 6, 9, 12, 15, 18]"
              outlined
              @input="filterLimit"
              v-model="limit"
              dense
              style="border-radius: 0; width: 70%"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="6" sm="6" md="6" class="d-flex align-baseline justify-base">
          <v-col cols="4">
            <div>{{ $t("itemsPage.sortBy") }}</div>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
              flat
              filled
              :items="$t('itemsPage.sort')"
              @input="filterSort"
              v-model="sort"
              clearable
              outlined
              dense
              style="border-radius: 0; width: 80%"
            ></v-autocomplete>
          </v-col>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="!isMobile">
      <div class="pr-5 d-flex justify-end">
        <div style="background: #dedede; border-radius: 5px">
          <v-btn
            @click="gridSmall"
            icon
            plain
            :ripple="false"
            :color="smallGridBlack"
          >
            <v-icon>mdi-grid </v-icon>
          </v-btn>
          <v-btn
            @click="gridBig"
            icon
            plain
            :ripple="false"
            :color="largeGridBlack"
          >
            <v-icon>mdi-grid-large </v-icon>
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TopBar",
  props: ["meta", "screenWidth"],
  data() {
    return {
      smallGridBlack: "black",
      largeGridBlack: "none",
      limit: 9,
      sort: "",
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 960 ? true : false;
    },
  },
  methods: {
    filterLimit() {
      this.$emit("filterLimit", this.limit);
    },
    filterSort() {
      this.$emit("filterSort", this.sort);
    },
    gridBig() {
      this.largeGridBlack = "black";
      this.smallGridBlack = "none";

      this.$emit("gridTypeChange", "large");
    },
    gridSmall() {
      this.smallGridBlack = "black";
      this.largeGridBlack = "none";

      this.$emit("gridTypeChange", "small");
    },
  },
};
</script>